.footer {
  background-color: #c6eae3;
  color: white;
  margin-top: 0px;
  padding-bottom: 20px;
}

.footer h2 {
  font-family: "Poppins", sans-serif;
  font-size: 2.2rem;
  font-weight: 600;
}

.footer h3 {
  font-family: "Poppins", sans-serif;
  font-size: 2rem;
  font-weight: 600;
}

.footer p {
  font-family: "Poppins", sans-serif;
  font-size: 1.8rem;
  font-weight: 400;
}

.w-70 {
  width: 70%;
  margin: auto;
}

.fw {
  font-weight: 800;
  font-style: italic;
}

.fs-19 {
  font-size: 19px;
}

.fs-26 {
  font-size: 26px;
}

.box-shadow-popup {
  box-shadow: 0px 3px 3px 3px rgb(0, 0, 0, 0.1);
}
/* .footer p a {
    font-family: "Poppins", sans-serif;
    font-size: 1.8rem;
    font-weight: 400;
    list-style: none;
    margin-top: 10px;
    color: white;
    text-decoration: underline;
  } */

.ISOCeritfied {
  display: flex;
}

.text-left {
  text-align: left;
}

.amnet-email p {
  font-size: 1.5rem;
}

.amnet-copyright p {
  font-size: 1.5rem;
}

.useful-links li a {
  font-family: "Poppins", sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  list-style: none;
  margin-top: 10px;
  color: white;
}

.useful-links li {
  font-family: "Poppins", sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  list-style: none;
  margin-top: 10px;
  color: white;
}

hr {
  margin-top: 4rem !important;
  width: 100%;
  border-color: white;
  background-color: white;
}

.amnet-email {
  margin-right: auto;
}

.amnet-copyright {
  margin-left: auto;
}

/* ICONS */
.footer .fa {
  padding: 20px;
  font-size: 1.2rem;
  width: 50px;
  text-align: center;
  text-decoration: none;
  margin: 25px 10px 2px 0px;
  border-color: white;
  background-color: #18ae8e;
  border-style: solid;
  border-radius: 4px;
  border-width: 1px;
}

.fa:hover {
  opacity: 0.8;
}

.fa-facebook {
  background: #3b5998;
  color: white;
}

.fa-twitter {
  background: #55acee;
  color: white;
}

.fa-instagram {
  background: #125688;
  color: white;
}

.fa-linkedin {
  background: #007bb5;
  color: white;
}

.listName {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  padding: 0px 5px;
  color: #555555;
}

.cr-pointer {
  cursor: pointer;
}

.text-black {
  color: #000000 !important;
}

.fs-13 {
  font-size: 13px;
}

.icon-footer {
  top: 6px;
  right: 25px;
}

.para_footer {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  color: #072b23;
}

.input_field input {
  padding-left: 10px;
  color: #939699;
  position: relative;
  box-shadow: none;
}

.list-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  color: #072b23;
}

.img-top {
  background: #ced4da;
  border-radius: 100%;
  padding: 11px;
}

.footer-border {
  border-bottom: 2px solid #d9dadb;
}

.bg-white {
  background-color: #ffffff;
  height: 200px;
}

.center-85{
  width:85%;
  margin: auto;
}

.center-80{
  width:80%;
  margin: auto;
}

.center-88 {
  width: 88%;
  margin: auto;
}

.center-70 {
  width: 70%;
  margin: auto;
}

.heading-padding {
  padding-left: 20px;
}

@media only screen and (min-width: 375px) and (max-width: 767px) {
  .center-70 {
    width: 95%;
    margin: auto;
  }

  .heading-padding {
    padding-left: 0px;
  }
}

.desName {
  font-size: 13px;
  color: black;
}

.padding-follow {
  padding-left: 100px;
}

.my-tooltip {
  display: flex;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.my-tooltip button {
  border: hidden;
  background-color: #18ae8e;
  color: #007bb5;
  outline: none;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  border-radius: 10px;
  width: 120px;
  height: 50px;
}

.hovertip {
  font-size: 13px;
  color: black;
}

.tooltip-box {
  background-color: #18ae8e;
  border-radius: 6px;
}

.bg-green {
  background-color: #072b23;
}

.h-auto {
  height: auto;
}

.fs-20 {
  font-size: 20px;
}

.thank-style {
  font-weight: 900;
  font-family: "Poppins";
  font-size: 22px;
}

.fs-12 {
  font-size: 12px;
}

.center-70 {
  width: 70%;
  margin: auto;
}

.heading-padding {
  padding-left: 20px;
}

@media only screen and (min-width: 280px) and (max-width: 767px) {
  .center-70 {
    width: 95%;
    margin: auto;
  }

  .heading-padding {
    padding-left: 0px;
  }
}

.achorhover:hover {
  text-decoration: none !important;
  color: black;
}
