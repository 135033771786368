* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

.w-5 {
  width: 5%;
}

.w-20{
  width: 20%;
}

.width-18{
  width: 18%;
}

.fs-25{
  font-size: 25px;
}
.h-15 {
  height: 15px;
}

.w-95 {
  width: 95%;
}

.w-3 {
  width: 3%;
}
.w-97 {
  width: 97%;
}

.h-75-px {
  height: 97vh;
}

.webinar_main_img {
  width: 100%;
  background-image: url("../images/Webinar-images/Bg-webinar-Img.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}

.insights_main_img {
  width: 100%;
  background-image: url("../images/swiftinisghts/main_background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}

.w-10 {
  width: 10px;
}

.w-15 {
  width: 15px;
}

.w-22 {
  width: 3rem;
}

.border-right {
  border-right: 5px solid #ffffff;
}

.w-350 {
  width: 350px;
}

.w-200 {
  width: 200px;
}

.pt-5-rem {
  padding-top: 5rem;
}

.pb-5-rem {
  padding-bottom: 5rem;
}

.pt-3-rem {
  padding-top: 3rem;
}

.pb-3-rem {
  padding-bottom: 3rem;
}

.fs-40 {
  font-size: 40px;
}

.fs-50 {
  font-size: 50px;
}

.mt-10-rem {
  margin-top: 10rem;
}

.mb-10-rem {
  margin-bottom: 10rem;
}

.mt-5-rem {
  margin-top: 5rem !important;
}

.mb-5-rem {
  margin-bottom: 5rem !important;
}

.mt-3-rem {
  margin-top: 3rem;
}

.mb-3-rem {
  margin-bottom: 3rem;
}

.mt-2-rem {
  margin-top: 2rem;
}

.join-styles {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #f79424;
}

.w-300 {
  width: 300px;
  height: 300px;
}

.w-40 {
  width: 40px;
}

.lh-1 {
  line-height: 1rem;
}

.main_heading {
  font-size: 37px;
  color: #fff;
  line-height: 1.25;
}

.fs-30 {
  font-size: 30px;
}

.timing_webniar {
  color: #fff;
  line-height: 1;
  font-size: 18px;
  font-weight: lighter;
}

.card_header_styles {
  color: #412881;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
}

.card-header {
  background-color: #fff !important;
  border-bottom: 0px solid rgba(0, 0, 0, 0.125) !important;
}

label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #000;
}

input {
  border: none !important;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  font-size: 16px;
  height: 40px;
}

.checkMark {
  box-shadow: none;
  font-size: 0px;
  height: 25px;
  width: 25px !important;
  border-color: #1dad8f !important;
}

.email_field input {
  width: 100%;
}

.req {
  color: #1dad8f;
}

.para-form {
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: #000000;
}

.center-98 {
  width: 98%;
  margin: auto;
}

.mt-n6 {
  margin-top: -7rem;
}

.submit-button {
  background-color: #1dad8f;
  color: #fff !important;
  border: 1px solid #1dad8f;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  font-size: 18px;
  text-align: center;
  line-height: 3px;
  font-weight: bold;
}

.condition-para {
  font-style: italic;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: #acacac;
}

.clear {
  clear: both;
}

.main-content-para {
  margin-top: 0px;
}

.fs-16 {
  font-size: 16px;
}

.fs-14 {
  font-size: 14px;
}

.fs-12 {
  font-size: 12px;
}

.fs-24 {
  font-size: 24px;
}

.fs-20 {
  font-size: 20px;
}

.fs-18 {
  font-size: 18px;
}

.fs-48 {
  font-size: 48px;
}

.key-take-aways {
  margin-bottom: 86em;
}

.key-take-aways h1 {
  font-size: 3.5rem;
  font-weight: 800;
  color: black;
  margin-top: 25px;
}

.key-takeaways {
  font-weight: 400;
  line-height: 32px;
  color: #000000;
}

ul {
  list-style: none;
}

.ul-keys li:before {
  content: "✓";
  font-weight: bold;
  font-size: 20px;
}

.li-para {
  display: flex;
}

.learn-img {
  width: 100%;
  background: #141b2b;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}

.learn-title {
  font-weight: 600;
  font-size: 48px;
  line-height: 60px;
  text-align: center!important;
  color: #ffffff;
  margin: auto;
  padding-top: 50px;
}

.learn-title p {
  margin-top: 25px;
}

.learn-content {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #ffffff;
}

.director-name {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
}

.director-title {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #ffffff;
}

.webinar-footer {
  background: #f2f2f2;
  width: 100%;
}

.support-email p {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #555555;
  text-align: center;
  margin-top: 40px;
}

.social-media {
  text-align: center;
  margin-top: 40px;
}

.cr-pointer {
  cursor: pointer;
}

.clr-orange {
  background-color: #f79424;
  border: none;
}

.center-95 {
  width: 95%;
  margin: auto;
}

.center-90 {
  width: 90%;
  margin: auto;
}

.lh-1-25 {
  line-height: 1.25;
}

.fs-10 {
  font-size: 10px;
}

.fs-18 {
  font-size: 18px;
}

.fs-16 {
  font-size: 16px;
}

.w-200 {
  width: 200px;
}

.w-20{
  width:20% !important;
}

.h-180 {
  height: 180px;
}

.lh-22 {
  line-height: 22px !important;
}

.lh-20 {
  line-height: 20px !important;
}

.d-table {
  display: table;
}

.box-shadow {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

.card-styles {
  height: 500px;
  width: 100%;
  color: #f79424;
  font-size: 28px;
  position: relative;
}

.successfull-msg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -25%);
}

.modal-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-content {
  background-color: #ffffff !important;
}
.img-class {
  width: 15px;
  position: absolute;
  right: 18px;
}
.btn-color {
  color: #ffffff;
  background-color: #ecac27;
  box-shadow: 3px 6px 16px #00000029;
}
.clr-blue {
  color: #412881;
}
.bg-left {
  background-color: #1dad8f;
  color: #ffffff;
}
.bg-right {
  cursor: pointer;
  background-color: #f79424;
  color: #ffffff;
 
}

.bg-btn-clr {
  background-color: #f79424;
}
.highlighted {
  border: 2px solid #000000;
}
.unhighlighted {
  border: 0px solid #000000;
}
.h-120 {
  height: 120px;
}
.my-modal {
  width: 90vw; /* Occupy the 90% of the screen width */
  max-width: 90vw;
  margin: auto;
}

.my-modal-form {
  width: 90vw; /* Occupy the 90% of the screen width */
  max-width: 90vw;
}

.my-modal .modal-content {
  height: auto;
}

.my-modal-form .modal-content {
  height: auto;
}

.exactCenter {
  position: absolute;
  top: 50%;
  left: 15%;
}

.w-2 {
  width: 2%;
}

.checkMark1 {
  font-size: 0px;
  height: 20px;
  width: 20px !important;
  border-color: #1dad8f !important;
  box-shadow: none;
  background: none;
}
@media (max-width: 500px) {
  .reverse-columns-mobile {
    flex-direction: column-reverse;
  }
}

.btn-close {
  box-sizing: content-box;
  width: 10em;
  height: 10em;
  padding: 0.25em;
  color: #000;
  background-image: url("../images/Webinar-images/close_img.jpg");
  border: 0;
  border-radius: 0.375rem;
  opacity: 0.5;
  background-size: cover;
}

.list-style-dot {
  list-style-type: disc;
}

.google-links li a {
  text-decoration: underline;
  margin-bottom: 5px;
}

.cookie-table th {
  background-color: #c6eae3;
  border: 1px solid #e2e2e2;
  border-collapse: collapse;
}

.cookie-table,
th,
td {
  border: 1px solid #e2e2e2;
  border-collapse: collapse;
}

.clr-updated {
  color: #1dad8f;
}

.legal-header {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  color: #000000;
}

.updated {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #747474;
}

.description-legal {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #1dad8f;
  cursor: pointer;
}

@media only screen and (max-width: 750px) {
  .w-350 {
    width: 300px;
  }
}
