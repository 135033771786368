/* NAVIGATION BAR */
/* ............................................................................................................................ */
.position-fixed {
  position: fixed;
  z-index: 99;
  width: 100%;
}

.navigation-bar {
  width: 100%;
  height: 80px;
  vertical-align: middle;
  z-index: 99;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.navbar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.5rem 0rem !important;
}

.bgc-white {
  background-color: #ffffff !important;
}

.navbar-nav .active a {
  color: #9de0d2;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.8225rem !important;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
  margin-left: 100px;
}

.navbar-nav {
  margin-right: 100px;
  margin-left: auto;
}

/* NAVBAR TEST SECTION */

.navbar-nav .active a {
  color: #9de0d2 !important;
}

.navbar-brand .logo {
  width: 244px;
  /* height: 54px; */
}

.navbar-nav .menu {
  padding: 0px 20px;
}

.navbar__link--active {
  color: red;
}

.custom-toggler {
  background-color: transparent;
}

.custom-toggler .navbar-toggler-icon {
  width: 25px;
  height: 3px;
  background-color: black;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}

.custom-toggler.navbar-toggler {
  border-color: none;
}

.navbar-nav .contactus-button {
  text-decoration: "none";
  color: white;
  font-size: 14px;
  font-weight: 700;
  padding: 11px 10px;
  margin-top: 15px;
  border: 0px;
  border-radius: 4px;
  text-align: center;
  height: 45px;
  width: 150px;
  margin-left: 10px;
  background-color: #1dad8f;
  margin-bottom: 10px;
}

.navbar-nav .contactus-button:hover {
  background-color: white;
  color: black;
  text-decoration: none;
}

/* FOR STICKY NAVBAR */
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

/* SUB-SERVICE-DROPDOWN */
.sub-service-dropdown .dropdown-menu {
  padding: 15px;
}

.sub-service-dropdown-item {
  font-size: 1.5rem;
  font-weight: 400px;
  padding: 10px 0px !important;
  text-align: left !important;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: center;
  list-style: none;
  background-color: #19af8e1a !important;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-content {
  display: none;
  position: absolute;
  margin-top: 15px;
  right: 40px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown-content-blog {
  display: none;
  position: absolute;
  margin-top: 30px;
  right: 40px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.text-new {
  color: #555555 !important;
}

.border-bottom-black{
  border-bottom: 1px black solid;
  margin-bottom:6px;
}

.text-digital {
  color: #555555 !important;
}

.cr-pointer {
  cursor: pointer;
}

.bg-white {
  background-color: #ffffff;
  height: 200px;
}

.text-black {
  color: black;
}

.text-white {
  color: white;
}

.bg-nav-white {
  background-color: white;
  padding-left: 40px;
  padding-right: 40px;
}

.w-20 {
  width: 35px;
  height: 35px;
}

.service-aiml a {
  font-size: 13px;
  font-weight: 700px;
  color: black;
}

.service-aiml span {
  font-size: 13px;
  font-weight: 700px;
  color: black;
}

.service-aiml span:hover {
  text-decoration: underline;
}

.w-85{
  width:85%;
}

.bg-dropdown {
  background: #f0f4f4;
  padding-left: 40px;
}

/* .hr-line {
  border-style: solid;
  border-width: 1px;
  border-color: #707070;
} */

.hr-separation{
  border-style: solid;
  border-width: 1px;
  border-color: #D9D9D9;
}


.text-underline {
  text-decoration: underline;
}

.w-30 {
  width: 40px;
}

.fa-angle-right {
  font-size: 2.1rem !important;
}

.dropdown-content .nav-first-column {
  margin-left: 140px;
}

.dropdown-view-all-link {
  margin-top: -5px;
}

.dropdown-view-all-link a {
  color: #19af8f !important;
  font-weight: 500 !important;
  font-size: 13px;
  padding-bottom: 5px;
  text-decoration: underline !important;
}

.dropdown-view-all-link span {
  color: #19af8f !important;
  font-weight: 500 !important;
  font-size: 13px;
  padding-bottom: 5px;
  text-decoration: underline !important;
  margin-top: 3px;
}

.dropdownd-ai-viewall-link a {
  color: #fd5d14 !important;
}

.column .sub-service-links {
  display: block;
  margin-block-start: 0em !important;
  margin-block-end: 0em !important;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.dropdown-icon {
  display: none;
}

.dropdown-content .header {
  background: red;
  padding: 16px;
  color: white;
}

.service-dropdown-menu:hover {
  transition: 2s;
}

.service-dropdown-menu:hover .dropdown-content {
  display: block;
  transition: 2s;
}

.sub-menu {
  display: flex;
  justify-content: center;
}

/* Create three equal columns that floats next to each other */
.column {
  float: left;
  width: 21%;
  padding: 25px 10px;
  background-color: white;
  height: auto;
}

.col-padding {
  padding: 5px 10px;
}

.extra-column {
  float: left;
  width: 25%;
  padding: 25px 10px;
  background-color: white;
  height: auto;
}

.service-dropdown-link a {
  float: none;
  color: black;
  padding-top: 7px;
  font-size: 1.7rem;
  text-decoration: none;
  display: block;
  text-align: left;
  font-weight: 800;
}

.column a:hover {
  color: #19af8f !important;
  text-decoration: none;
}

.dropdown-content p {
  font-size: 1.3rem;

  padding-right: 15px;
  text-align: left;
  color: black;
}

.sub-service-links a {
  font-size: 13px;
  font-weight: 400;
  padding-right: 15px;
  padding-top: 5px;
  text-align: left;
  color: #555555;
}

.sub-service-view a {
  font-size: 13px;
  font-weight: 500;
  padding-right: 15px;
  padding-top: 5px;
  text-align: left;
  color: #000000;
}

.sub-service-links-insights a {
  font-size: 1.3rem;
  font-weight: 700;
  padding-right: 10px;
  text-align: left;
  color: black;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.sub-service-dropdown {
  display: none;
}

.navFlex:hover img {
  transform: scale(1.1);
  transition: 0.5s;
}

/* INDUSTRY NAVBAR DROPDOWN */

.industry-dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  width: 100%;
  margin-top: 30px;
  left: 0;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* .industry-dropdown-content {
    display: none;
    background-color: white !important;
    left: 0;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    padding: 15px;
    margin-top: 4px;
    border-radius: 0px 0px 8px 8px;
  } */

.industry-dropdown-content .industry-dropdown-list {
  font-size: 1.5rem;
  font-weight: 400px;
  color: black;
  font-weight: 600;
  padding: 10px 0px !important;
  text-align: left !important;
}

.industry-dropdown-item {
  font-size: 1.5rem;
  font-weight: 400px;
  color: white;
}

@media screen and (max-width: 991px) {
}

/* NAVBAR RESPONSIVE SECTION */

@media screen and (max-width: 830px) {
  .column {
    width: 100%;
    height: auto;
  }

  .sub-menu:hover .dropdown-content {
    display: none;
  }

  .dropdown-icon {
    display: block;
  }

  .sub-service-dropdown {
    display: block;
  }

  .service-dropdown-menu {
    display: none;
  }

  .navbar-nav .service-dd {
    display: block;
  }
}

.dropdown-item.active,
.dropdown-item:active {
  color: #000000;
  text-decoration: none;
  background-color: transparent !important;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #19af8f !important;
  text-decoration: none;
  background-color: transparent !important;
}

/* .service-dropdown-menu:hover {
  border-bottom: 5px solid #19af8f;
  transition: 0.5s;
} */

.only-service-dropdown:hover {
  border-bottom: 0px solid #19af8f;
  transition: none;
}

.only-service-dropdown:hover:after {
  content: "";
  position: absolute;
  top: 58px;
  left: 57%;
  margin-left: -15px;
  width: 0px;
  height: 0px;
  margin: 0px auto;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
}

.only-blog-dropdown:hover:after {
  content: "";
  position: absolute;
  top: 60px;
  left: 80%;
  margin-left: -15px;
  width: 0px;
  height: 0px;
  margin: 0px auto;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
}

.navFlex img {
  width: 50px;
  height: 50px;
  margin-left: 10px;
}

.navFlex {
  display: flex;
  margin-top: 10px;
}

.nav-sub-menu {
  width: 100%;
  margin-right: 30px;
  margin-left: 30px;
}

.dropdown-toggle::after {
  display: none !important;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.service-dd {
  display: none;
}

@media only screen and (min-width: 320px) and (max-width: 374px) {
  nav.navbar {
    max-height: 100vh;
    overflow: auto;
  }

  .navbar-nav {
    margin-right: 0px;
  }

  .navbar-brand {
    margin-left: 21px;
  }

  .navbar-toggler {
    margin-right: 21px;
  }

  .navigation-bar {
    height: 80px;
  }

  .navbar-toggler {
    padding: 0.55rem 0.75rem 1.15rem !important;
  }

  .navbar-brand .logo {
    width: 175px;
    height: 40px;
    margin-top: 10px;
  }

  .navigation-bar {
    text-align: left;
  }

  .navbar-collapse {
    background-color: #ffffff;
    padding: 5px;
    z-index: 1;
  }
}

/* FOR SMALLER DEVICES 375PX */
/* ...................................................................................................................... */

@media only screen and (min-width: 375px) and (max-width: 767px) {
  nav.navbar {
    max-height: 100vh;
    overflow: auto;
  }

  .navbar-nav {
    margin-right: 0px;
    margin-left: 10px;
  }

  .navbar-brand {
    margin-left: 21px;
  }

  .navbar-toggler {
    margin-right: 21px;
  }

  .navigation-bar {
    height: 80px;
  }

  .navbar-toggler {
    padding: 0.55rem 0.75rem 1.15rem !important;
  }

  .navbar-brand .logo {
    width: 175px;
    height: 40px;
    margin-top: 10px;
  }

  .navigation-bar {
    text-align: left;
  }

  .navbar-collapse {
    background-color: #ffffff;
    padding: 5px;
    z-index: 1;
  }
}

/* FOR MEDIUM DEVICES 834PX */
/* ...................................................................................................................... */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  nav.navbar {
    max-height: 100vh;
    overflow: auto;
  }

  .navigation-bar {
    height: 80px;
  }

  .navbar-toggler {
    padding: 0.25rem 0.75rem 1.15rem !important;
  }

  .navbar-brand .logo {
    margin-top: 5px;
  }

  .navbar-brand {
    margin-left: 40px;
  }

  .navbar-nav {
    margin-right: 70px;
    margin-left: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .navbar-toggler {
    margin-right: 81px;
  }

  .navbar-brand .logo {
    width: 200px;
    height: 45px;
  }

  .navbar-collapse {
    background-color: #ffffff;
    padding: 5px;
    z-index: 1;
  }

  /* DROPDOWN MENU */

  .dropdown-content .nav-first-column {
    margin-left: 81px !important;
  }

  .dropdown-content .extra-column {
    margin-left: 81px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .navbar-collapse {
    background-color: transparent;
  }
}

@media only screen and (min-width: 320px) and (max-width: 1024px) {
  .navbar-brand {
    margin-left: 20px;
  }

  .navbar-nav {
    margin-right: 20px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1439px) {
  .navbar-brand {
    margin-left: 60px;
  }

  .navbar-nav {
    margin-right: 60px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1359px) {
  .dropdown-content .column {
    float: left;
    width: 27%;
    padding: 5px 10px;
    background-color: white;
    height: auto;
    border-radius: 20px 0px;
  }

  .service-dropdown-link a {
    padding-top: 2px;
  }

  .navFlex img {
    width: 40px !important;
    height: 40px !important;
  }

  .service-dropdown-link a {
    font-size: 1.5rem !important;
  }

  .sub-service-links a {
    font-size: 1.2rem !important;
  }

  .dropdown-content .nav-first-column {
    margin-left: 81px !important;
  }

  .extra-column .navFlex {
    margin-top: 0px;
  }

  .dropdown-content .extra-column {
    float: left;
    width: 25%;
    padding: 0px 10px;
    background-color: white;
    height: auto;
    border-radius: 0px 20px;
    margin-left: 81px;
  }
}

@media only screen and (min-width: 1360px) and (max-width: 1439px) {
  .dropdown-content .nav-first-column {
    margin-left: 140px !important;
  }

  .dropdown-content .extra-column {
    float: left;
    width: 25%;
    padding: 21px 10px;
    background-color: white;
    height: auto;
    border-radius: 0px 20px;
  }
}

/* resources styles */

.margin-resources-top {
  padding-top: 100px;
}
.bg-color-black {
  background-color: #000000;
}
.small_banner {
  background-color: #412881;
  width: 100%;
  text-align: center;
}

@media only screen and (min-width: 1500px) and (max-width: 2560px) {
  .only-service-dropdown:hover:after {
    content: "";
    position: absolute;
    top: 58px;
    left: 75%;
    margin-left: -15px;
    width: 0px;
    height: 0px;
    margin: 0px auto;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
  }
}

.sub-service-links span {
  font-size: 13px;
  font-weight: 400;
  padding-right: 15px;
  padding-top: 5px;
  text-align: left;
  color: #555555;
  cursor: pointer;
}

.arrow-up {
  border-color: transparent transparent #fff;
  border-style: solid;
  border-width: 10px;
  height: 0px;
  width: 0px;
  margin: auto;
}

.arrow-up-insights {
  border-color: transparent transparent #fff;
  border-style: solid;
  border-width: 10px;
  height: 0px;
  width: 0px;
  margin: auto;
}

.hide {
  display: none;
}

.show {
  display: block;
}

/* NAVIGATION BAR */
/* ............................................................................................................................ */
.position-fixed {
  position: fixed;
  z-index: 99;
  width: 100%;
}

.navigation-bar {
  width: 100%;
  height: 80px;
  vertical-align: middle;
  z-index: 99;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.navbar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.5rem 0rem !important;
}

.bgc-white {
  background-color: #ffffff !important;
}

.navbar-nav .active a {
  color: #9de0d2;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.8225rem !important;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
  margin-left: 100px;
}

.navbar-nav {
  margin-right: 100px;
  margin-left: auto;
}

/* NAVBAR TEST SECTION */

.navbar-nav .active a {
  color: #9de0d2 !important;
}

.navbar-brand .logo {
  width: 244px;
  /* height: 54px; */
}

.navbar-nav .menu {
  padding: 0px 20px;
}

.navbar__link--active {
  color: red;
}

.custom-toggler {
  background-color: transparent;
}

.custom-toggler .navbar-toggler-icon {
  width: 25px;
  height: 3px;
  background-color: black;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}

.custom-toggler.navbar-toggler {
  border-color: none;
}

.navbar-nav .contactus-button {
  text-decoration: "none";
  color: white;
  font-size: 14px;
  font-weight: 700;
  padding: 11px 10px;
  margin-top: 15px;
  border: 0px;
  border-radius: 4px;
  text-align: center;
  height: 45px;
  width: 150px;
  margin-left: 10px;
  background-color: #1dad8f;
  margin-bottom: 10px;
}

.navbar-nav .contactus-button:hover {
  background-color: white;
  color: black;
  text-decoration: none;
}

/* FOR STICKY NAVBAR */
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

/* SUB-SERVICE-DROPDOWN */
.sub-service-dropdown .dropdown-menu {
  padding: 15px;
}

.sub-service-dropdown-item {
  font-size: 1.5rem;
  font-weight: 400px;
  padding: 10px 0px !important;
  text-align: left !important;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: center;
  list-style: none;
  background-color: #19af8e1a !important;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-content {
  display: none;
  position: absolute;
  margin-top: 15px;
  right: 40px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown-content-blog {
  display: none;
  position: absolute;
  margin-top: 30px;
  right: 40px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown-content-products{
  display:none;
  position:absolute;
  margin-top: 30px;
  right: 40px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.text-new {
  color: #555555 !important;
}

.text-digital {
  color: #555555 !important;
}

.cr-pointer {
  cursor: pointer;
}

.bg-white {
  background-color: #ffffff;
  height: 200px;
}

.text-black {
  color: black;
}

.text-white {
  color: white;
}

.bg-nav-white {
  background-color: white;
  padding-left: 40px;
  padding-right: 40px;
}

.w-20 {
  width: 35px;
  height: 35px;
}

.service-aiml a {
  font-size: 13px;
  font-weight: 700px;
  color: black;
}

.service-aiml span {
  font-size: 13px;
  font-weight: 700px;
  color: black;
}

.service-aiml span:hover {
  text-decoration: underline;
}

.bg-dropdown {
  background: #f0f4f4;
  padding-left: 40px;
}

.hr-line {
  border: 1px solid #707070;
}

.hr-separation{
  border-style: solid;
  border-width: 1px;
  border-color: #D9D9D9;
}


.text-underline {
  text-decoration: underline;
}

.w-30 {
  width: 40px;
}

.fa-angle-right {
  font-size: 2.1rem !important;
}

.dropdown-content .nav-first-column {
  margin-left: 140px;
}

.dropdown-view-all-link {
  margin-top: -5px;
}

.dropdown-view-all-link a {
  color: #19af8f !important;
  font-weight: 500 !important;
  font-size: 13px;
  padding-bottom: 5px;
  text-decoration: underline !important;
}

.dropdown-view-all-link span {
  color: #19af8f !important;
  font-weight: 500 !important;
  font-size: 13px;
  padding-bottom: 5px;
  text-decoration: underline !important;
  margin-top: 3px;
}

.dropdownd-ai-viewall-link a {
  color: #fd5d14 !important;
}

.column .sub-service-links {
  display: block;
  margin-block-start: 0em !important;
  margin-block-end: 0em !important;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.dropdown-icon {
  display: none;
}

.dropdown-content .header {
  background: red;
  padding: 16px;
  color: white;
}

.service-dropdown-menu:hover {
  transition: 2s;
}

.service-dropdown-menu:hover .dropdown-content {
  display: block;
  transition: 2s;
}

.sub-menu {
  display: flex;
  justify-content: center;
}

/* Create three equal columns that floats next to each other */
.column {
  float: left;
  width: 21%;
  padding: 25px 10px;
  background-color: white;
  height: auto;
}

.col-padding {
  padding: 5px 10px;
}

.extra-column {
  float: left;
  width: 25%;
  padding: 25px 10px;
  background-color: white;
  height: auto;
}

.service-dropdown-link a {
  float: none;
  color: black;
  padding-top: 7px;
  font-size: 1.7rem;
  text-decoration: none;
  display: block;
  text-align: left;
  font-weight: 800;
}

.column a:hover {
  color: #19af8f !important;
  text-decoration: none;
}

.dropdown-content p {
  font-size: 1.3rem;

  padding-right: 15px;
  text-align: left;
  color: black;
}

.sub-service-links a {
  font-size: 13px;
  font-weight: 400;
  padding-right: 15px;
  padding-top: 5px;
  text-align: left;
  color: #555555;
}

.sub-service-view a {
  font-size: 13px;
  font-weight: 500;
  padding-right: 15px;
  padding-top: 5px;
  text-align: left;
  color: #000000;
}

.sub-service-links-insights a {
  font-size: 1.3rem;
  font-weight: 700;
  padding-right: 10px;
  text-align: left;
  color: black;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.sub-service-dropdown {
  display: none;
}

.navFlex:hover img {
  transform: scale(1.1);
  transition: 0.5s;
}

/* INDUSTRY NAVBAR DROPDOWN */

.industry-dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  width: 100%;
  margin-top: 30px;
  left: 0;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* .industry-dropdown-content {
    display: none;
    background-color: white !important;
    left: 0;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    padding: 15px;
    margin-top: 4px;
    border-radius: 0px 0px 8px 8px;
  } */

.industry-dropdown-content .industry-dropdown-list {
  font-size: 1.5rem;
  font-weight: 400px;
  color: black;
  font-weight: 600;
  padding: 10px 0px !important;
  text-align: left !important;
}

.industry-dropdown-item {
  font-size: 1.5rem;
  font-weight: 400px;
  color: white;
}

@media screen and (max-width: 991px) {
}

/* NAVBAR RESPONSIVE SECTION */

@media screen and (max-width: 830px) {
  .column {
    width: 100%;
    height: auto;
  }

  .sub-menu:hover .dropdown-content {
    display: none;
  }

  .dropdown-icon {
    display: block;
  }

  .sub-service-dropdown {
    display: block;
  }

  .service-dropdown-menu {
    display: none;
  }

  .navbar-nav .service-dd {
    display: block;
  }
}

.dropdown-item.active,
.dropdown-item:active {
  color: #000000;
  text-decoration: none;
  background-color: transparent !important;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #19af8f !important;
  text-decoration: none;
  background-color: transparent !important;
}

/* .service-dropdown-menu:hover {
  border-bottom: 5px solid #19af8f;
  transition: 0.5s;
} */

.only-service-dropdown:hover {
  border-bottom: 0px solid #19af8f;
  transition: none;
}

.only-service-dropdown:hover:after {
  content: "";
  position: absolute;
  top: 58px;
  left: 57%;
  margin-left: -15px;
  width: 0px;
  height: 0px;
  margin: 0px auto;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
}

.only-blog-dropdown:hover:after {
  content: "";
  position: absolute;
  top: 60px;
  left: 80%;
  margin-left: -15px;
  width: 0px;
  height: 0px;
  margin: 0px auto;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
}

.navFlex img {
  width: 50px;
  height: 50px;
  margin-left: 10px;
}

.navFlex {
  display: flex;
  margin-top: 10px;
}

.nav-sub-menu {
  width: 100%;
  margin-right: 30px;
  margin-left: 30px;
}

.dropdown-toggle::after {
  display: none !important;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.service-dd {
  display: none;
}

@media only screen and (min-width: 320px) and (max-width: 374px) {
  nav.navbar {
    max-height: 100vh;
    overflow: auto;
  }

  .navbar-nav {
    margin-right: 0px;
  }

  .navbar-brand {
    margin-left: 21px;
  }

  .navbar-toggler {
    margin-right: 21px;
  }

  .navigation-bar {
    height: 80px;
  }

  .navbar-toggler {
    padding: 0.55rem 0.75rem 1.15rem !important;
  }

  .navbar-brand .logo {
    width: 175px;
    height: 40px;
    margin-top: 10px;
  }

  .navigation-bar {
    text-align: left;
  }

  .navbar-collapse {
    background-color: #ffffff;
    padding: 5px;
    z-index: 1;
  }
}

/* FOR SMALLER DEVICES 375PX */
/* ...................................................................................................................... */

@media only screen and (min-width: 375px) and (max-width: 767px) {
  nav.navbar {
    max-height: 100vh;
    overflow: auto;
  }

  .navbar-nav {
    margin-right: 0px;
    margin-left: 10px;
  }

  .navbar-brand {
    margin-left: 21px;
  }

  .navbar-toggler {
    margin-right: 21px;
  }

  .navigation-bar {
    height: 80px;
  }

  .navbar-toggler {
    padding: 0.55rem 0.75rem 1.15rem !important;
  }

  .navbar-brand .logo {
    width: 175px;
    height: 40px;
    margin-top: 10px;
  }

  .navigation-bar {
    text-align: left;
  }

  .navbar-collapse {
    background-color: #ffffff;
    padding: 5px;
    z-index: 1;
  }
}

/* FOR MEDIUM DEVICES 834PX */
/* ...................................................................................................................... */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  nav.navbar {
    max-height: 100vh;
    overflow: auto;
  }

  .navigation-bar {
    height: 80px;
  }

  .navbar-toggler {
    padding: 0.25rem 0.75rem 1.15rem !important;
  }

  .navbar-brand .logo {
    margin-top: 5px;
  }

  .navbar-brand {
    margin-left: 40px;
  }

  .navbar-nav {
    margin-right: 70px;
    margin-left: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .navbar-toggler {
    margin-right: 81px;
  }

  .navbar-brand .logo {
    width: 200px;
    height: 45px;
  }

  .navbar-collapse {
    background-color: #ffffff;
    padding: 5px;
    z-index: 1;
  }

  /* DROPDOWN MENU */

  .dropdown-content .nav-first-column {
    margin-left: 81px !important;
  }

  .dropdown-content .extra-column {
    margin-left: 81px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .navbar-collapse {
    background-color: transparent;
  }
}

@media only screen and (min-width: 320px) and (max-width: 1024px) {
  .navbar-brand {
    margin-left: 20px;
  }

  .navbar-nav {
    margin-right: 20px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1439px) {
  .navbar-brand {
    margin-left: 60px;
  }

  .navbar-nav {
    margin-right: 60px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1359px) {
  .dropdown-content .column {
    float: left;
    width: 27%;
    padding: 5px 10px;
    background-color: white;
    height: auto;
    border-radius: 20px 0px;
  }

  .service-dropdown-link a {
    padding-top: 2px;
  }

  .navFlex img {
    width: 40px !important;
    height: 40px !important;
  }

  .service-dropdown-link a {
    font-size: 1.5rem !important;
  }

  .sub-service-links a {
    font-size: 1.2rem !important;
  }

  .dropdown-content .nav-first-column {
    margin-left: 81px !important;
  }

  .extra-column .navFlex {
    margin-top: 0px;
  }

  .dropdown-content .extra-column {
    float: left;
    width: 25%;
    padding: 0px 10px;
    background-color: white;
    height: auto;
    border-radius: 0px 20px;
    margin-left: 81px;
  }
}

@media only screen and (min-width: 1360px) and (max-width: 1439px) {
  .dropdown-content .nav-first-column {
    margin-left: 140px !important;
  }

  .dropdown-content .extra-column {
    float: left;
    width: 25%;
    padding: 21px 10px;
    background-color: white;
    height: auto;
    border-radius: 0px 20px;
  }
}

/* resources styles */

.margin-resources-top {
  padding-top: 100px;
}
.bg-color-black {
  background-color: #000000;
}
.small_banner {
  background-color: #412881;
  width: 100%;
  text-align: center;
}

@media only screen and (min-width: 1500px) and (max-width: 2560px) {
  .only-service-dropdown:hover:after {
    content: "";
    position: absolute;
    top: 58px;
    left: 75%;
    margin-left: -15px;
    width: 0px;
    height: 0px;
    margin: 0px auto;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
  }
}

.sub-service-links span {
  font-size: 13px;
  font-weight: 400;
  padding-right: 15px;
  padding-top: 5px;
  text-align: left;
  color: #555555;
  cursor: pointer;
}

.arrow-up {
  border-color: transparent transparent #fff;
  border-style: solid;
  border-width: 10px;
  height: 0px;
  width: 0px;
  margin: auto;
}

.arrow-up-insights {
  border-color: transparent transparent #fff;
  border-style: solid;
  border-width: 10px;
  height: 0px;
  width: 0px;
  margin: auto;
}

.arrow-up-products {
  border-color: transparent transparent #fff;
  border-style: solid;
  border-width: 10px;
  height: 0px;
  width: 0px;
  margin: auto;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.view-style{
  font-size:19px;
  color:black;
  font-weight:800;
}

