.letpara {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  color: #ffffff;
}

.content-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
}

.content-button {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
}

.connect {
  cursor: pointer;
  background: #fd5d14;
  border-radius: 34px;
}

.current-openings-contant {
  cursor: pointer;
  background: #1dad8f;
  border-radius: 4px;
  padding-left:30px;
  padding-right:30px;
  padding-top:12px;
  padding-bottom:12px;
  
}

.current-openings-connect {
  cursor: pointer;
  background: #1dad8f;
  border-radius: 4px;
  padding-left:31px;
  padding-right:35px;
  padding-top:12px;
  padding-bottom:12px; 
}


.button-width{
  width:260px;
  height:45px;
}

.lets-connect {
  cursor: pointer;
  background: #ffffff;
  border-radius: 34px;
}



.w-90 {
  width: 90%;
}
