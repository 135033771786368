.text-center-img {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .bottom-img {
    position: absolute;
    bottom: 40px;
  }

  .bottom-img-services{
    position:absolute;
    bottom: 30px;
  }

  .bottom-img-about{
    position:absolute;
    bottom: 10px !important;
  }

  .bottom-img-career{
    position:absolute;
    bottom: 15px;
  }


.fs-35 {
    font-size:35px;
}

.mb-4{
  margin-bottom: 1.5rem!important;
}

.rounded-img-corner{
  color: #1dad8f;
  border-style:solid;
  border-width:2px;
  border-radius:360px;
}

.fw-bold-600{
    font-weight:600;
}

.fs-16{
    font-size:16px;
    
}

.fw-bold-400{
    font-weight:400;
}

.text-black{
    color:black;
}

.fs-50{
    font-size:50px;
}

.fw-bold-700{
    font-weight:700;
}

.statistics-heading {
    font-style: normal;
    font-weight: bolder;
    font-size: 16px;
    color: black;
    text-align: left;
  }

.container-box {
    border: 1px solid #c4c4c4;
    padding-bottom: 0px;
  }

  .column-eq-height {
    display: table-cell;
  }

  .statistics-para {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #323232;
    text-align: left;
  }

  .small-grey-box {
    border: 1px solid #c4c4c4;
    align-items: right;
    color: #1dad8f;
    padding: 10px;
  }

.bottom-0{
    bottom: 0;
}

  .cr-pointer {
    cursor: pointer;
  }

  .position-realtive {
    position: relative;
  }

.fs-32{
    font-size:32px;
}

.mt-1{
    margin-top: 3rem !important;
}

.p{
    margin-bottom: 3rem !important;
}

.service_title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    text-transform: uppercase;
    color: #000000;
  }

  .center-91 {
    width: 91%;
    margin: auto;
  }

.space-read{
    margin-top: 40px;
}

.border-col1 {
    border-right: 2px solid rgba(0, 0, 0, 0.4);
    margin-right: 15px;
  }

  .company_name {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 50px;
    text-transform: uppercase;
    color: #000000;
    position: absolute;
    top: 50%;
    bottom:50%;
  }

.fw-bold-800{
    font-weight:800;
}

.fw-bold-400{
    font-weight:400;
}

  .text-center {
    text-align: center!important;
}

  .heading_why {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    color: #000000;
  }

  .scroll {
    height: calc(600px - 20px);
    overflow: auto;
}


@media only  screen and (min-width:280px) and (max-width:767px){
  .scroll {
    height: 400px;
    overflow: auto;
}

}

  .paragraph_why {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.4);
  }
  
  .image-border-amnet {
    border: 3px solid #ff6e31;
    border-radius: 100%;
  }

  
  #style-4::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5;
}

#style-4::-webkit-scrollbar-thumb {
    background-color: #000000;
}

#style-4::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
}

  @media screen and (max-width: 1024px) {
    .width_query {
      width: 100%;
    }
    .img-height {
      height: 600px;
    }
    .redesign-width {
      width: 100%;
    }
    .data-width {
      width: 100%;
    }
    .main-img-class {
      width: 100%;
    }
  }
  
  @media screen and (min-width: 1025px) {
    .width_query {
      width: 50%;
    }
    .img-height {
      height: 450px;
    }
    .redesign-width {
      width: 50%;
    }
    .data-width {
      width: 60%;
    }
    .main-img-class {
      width: 75%;
    }
  }

  .w-90 {
    width: 90%;
  }

  .mt-15 {
    margin-top: 3rem;
}

.mt-15{
  margin-top: 15rem;
}

.margin-15{
  margin-top:3rem;
}

.mt-15.mt-14 {
  margin-top:3rem;
}
 @media only screen and (min-width:270px) and (max-width:767px){
  .margin-15{
    margin-top:0rem;
  }
 }
.w-95{
    width: 95%;
}

.new-subService-AIML-intro-caption {
    top: 180px;
    color: white;
  }

  .new-all-margin {
    margin: 0px 100px 0px 100px;
  }

  @media only screen and (min-width: 320px) and (max-width: 424px) {
    .new-all-margin {
      margin: 0px 20px 0px 20px;
    }
}

@media only screen and (min-width: 425px) and (max-width: 767px) {
    .new-all-margin {
      margin: 0px 20px 0px 20px;
    }
}

@media only screen and (min-width: 425px) and (max-width: 767px) {
    .new-all-margin {
      margin: 0px 20px 0px 20px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
   
    .new-all-margin {
      margin: 0px 40px 0px 40px;
    }
}

.new-all-margin {
    margin: 0px 60px 0px 60px;
  }

  .space-drive {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  
  .drive {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    text-transform: capitalize;
    color: #ffffff;
  }
  
  .drive_para {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    color: #ffffff;
  }
  
  .btn_drive {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #ffffff;
    background: #ff6e31;
    border-radius: 40px;
    display: table;
    padding: 10px 20px 10px  20px;
  }
  
  
  