.text-center-img {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.fs-54 {
  font-size: 54px;
}

.fw-bold-700 {
  font-weight: 700;
}

.star-green {
  color: #18ae8e;
  font-size: 20px;
}

.font-form {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.email_field input {
  width: 100%;
}

.text-areainput {
  width: 100%;
  height: 170px;
  box-shadow: 3px 6px 16px #00000029;
  border-style: none;
  font-size: 1.8rem;
}

.mt-10 {
  margin-top: 6rem;
}

.mt-6 {
  margin-top: 6rem;
}

.mb-4 {
  margin-bottom: 4rem;
}

.bg-footer {
  background-color: #c6eae3;
}

.section-title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 40px;
  color: #000000;
}

.letpara {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  color: #ffffff;
}

.content-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
}

.current-openings {
  cursor: pointer;
  background: #1dad8f;
  border-radius: 4px;
  width: 227px;
  height: 45px;
  border-width: 0px;
  color: white;
}

.fs-16 {
  font-size: 16px;
}

.fw-bold-600 {
  font-weight: 600;
}

.container-table-box {
  box-shadow: 0px 3px 6px #00000029;
}
